import { notMissing } from "@ameelio/core";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FacilityFeature } from "@src/api/graphql";
import { mondayMorning } from "@src/lib/Call";
import DateRangePicker from "@src/lib/DateRangePicker";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import LineChart from "@src/lib/LineChart";
import PageLoader from "@src/lib/PageLoader";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useFacilityMetrics } from "@src/pages/ActivityPage/useFacilityMetrics";
import {
  Widget,
  WidgetRow,
  WidgetRowLayout,
} from "@src/pages/ActivityPage/Widget";
import ChartWithAverage from "@src/pages/ActivityPage/widgets/ChartWithAverage";
import { SelectedFacility } from "@src/typings/Facility";
import { endOfDay, endOfYesterday, subWeeks } from "date-fns";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

const { history } = window;

type Filters = {
  scheduledStartAfter: number | undefined;
  scheduledStartBefore: number;
};

type WidgetList = {
  layout: WidgetRowLayout;
  widgets: ReactNode[];
};

type WidgetGroup = {
  id: string;
  title: string;
  rows: WidgetList[];
};

export default function ActivityNew({
  facility,
}: {
  facility: SelectedFacility;
}) {
  const facilityHasVideoCalls = facility.features.includes(
    FacilityFeature.VideoCall
  );
  const facilityHasVoiceCalls = facility.features.includes(
    FacilityFeature.VoiceCall
  );
  const facilityHasVisits = facility.features.includes(
    FacilityFeature.InPersonVisit
  );
  const facilityHasConfidentialVideoCall = facility.features.includes(
    FacilityFeature.ConfidentialVideoCall
  );
  const facilityHasConfidentialInPersonVisit = facility.features.includes(
    FacilityFeature.ConfidentialInPersonVisit
  );
  const { t } = useTranslation();
  const ratingLabels = [
    t("Excellent"),
    t("Great"),
    t("Good"),
    t("Bad"),
    t("Terrible"),
  ];
  const startOfWeek = mondayMorning(new Date());
  const defaultReportStart = subWeeks(startOfWeek, 4);

  const [filters, setFilters] = useState<Filters>({
    scheduledStartAfter:
      isObjectWithKey(history.state, "scheduledStartAfter") &&
      typeof history.state.scheduledStartAfter === "number"
        ? history.state.scheduledStartAfter
        : defaultReportStart.getTime(),
    scheduledStartBefore:
      isObjectWithKey(history.state, "scheduledStartBefore") &&
      typeof history.state.scheduledStartBefore === "number"
        ? history.state.scheduledStartBefore
        : endOfYesterday().getTime(),
  });

  const reportStart =
    filters.scheduledStartAfter || defaultReportStart.getTime();
  const reportEnd = filters.scheduledStartBefore || endOfYesterday().getTime();

  const { data, loading, videoCalls, voiceCalls, inPersonVisits } =
    useFacilityMetrics({
      reportStart,
      reportEnd,
      facilityId: facility.id,
    });

  if (!data) return <PageLoader />;

  const videoCallWidgetGroup: WidgetGroup | undefined =
    videoCalls && (facilityHasVideoCalls || facilityHasConfidentialVideoCall)
      ? {
          id: "video-calls",
          title: t("Video calls"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(videoCalls.volume)}
                    data={Object.values(videoCalls.volume)}
                  />
                </Widget>,
                <Stack spacing={2}>
                  <Widget title={t("Total")} value={videoCalls.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${videoCalls.adoptionRate}%`}
                    explanation={t(
                      "Percentage of current residents participating in video calls over the selected period."
                    )}
                  />
                </Stack>,
              ],
            },
            {
              layout: WidgetRowLayout.HALF,
              widgets: [
                videoCalls.userRatings ? (
                  <ChartWithAverage
                    title={t("Visitor Ratings")}
                    data={videoCalls.userRatings.ratings}
                    labels={ratingLabels}
                    average={videoCalls.userRatings.average}
                  />
                ) : undefined,
                videoCalls.inmateRatings ? (
                  <ChartWithAverage
                    title={t("Resident Ratings")}
                    data={videoCalls.inmateRatings.ratings}
                    labels={ratingLabels}
                    average={videoCalls.inmateRatings.average}
                  />
                ) : undefined,
              ].filter(notMissing),
            },
          ],
        }
      : undefined;

  const inPersonVisitWidgetGroup: WidgetGroup | undefined =
    inPersonVisits &&
    (facilityHasVisits || facilityHasConfidentialInPersonVisit)
      ? {
          id: "in-person-visits",
          title: t("In-person visits"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(inPersonVisits.volume)}
                    data={Object.values(inPersonVisits.volume)}
                  />
                </Widget>,
                <Stack spacing={2}>
                  <Widget title={t("Total")} value={inPersonVisits.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${inPersonVisits.adoptionRate}%`}
                    explanation={t(
                      "Percentage of current residents participating in in-person visits over the selected period."
                    )}
                  />
                </Stack>,
              ],
            },
          ],
        }
      : undefined;

  const voiceCallWidgetGroup: WidgetGroup | undefined =
    voiceCalls && facilityHasVoiceCalls
      ? {
          id: "voice-calls",
          title: t("Voice calls"),
          rows: [
            {
              layout: WidgetRowLayout.TWO_THIRD,
              widgets: [
                <Widget title={t("Events per day")}>
                  <LineChart
                    label={t("# calls")}
                    labels={Object.keys(voiceCalls.volume)}
                    data={Object.values(voiceCalls.volume)}
                  />
                </Widget>,
                <Stack spacing={2}>
                  <Widget title={t("Total")} value={voiceCalls.total} />
                  <Widget
                    title={t("Adoption rate")}
                    value={`${voiceCalls.adoptionRate}%`}
                  />
                </Stack>,
              ],
            },
            {
              layout: WidgetRowLayout.HALF,
              widgets: [
                voiceCalls.userRatings ? (
                  <ChartWithAverage
                    title={t("Visitor Ratings")}
                    data={voiceCalls.userRatings.ratings}
                    labels={ratingLabels}
                    average={voiceCalls.userRatings.average}
                  />
                ) : undefined,
                voiceCalls.inmateRatings ? (
                  <ChartWithAverage
                    title={t("Resident Ratings")}
                    data={voiceCalls.inmateRatings.ratings}
                    labels={ratingLabels}
                    average={voiceCalls.inmateRatings.average}
                  />
                ) : undefined,
              ].filter(notMissing),
            },
          ],
        }
      : undefined;

  const widgetGroups: WidgetGroup[] = [
    videoCallWidgetGroup,
    voiceCallWidgetGroup,
    inPersonVisitWidgetGroup,
  ].filter(notMissing);

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  return (
    <>
      <Header title={t("Activity overview")}>
        <ResponsiveColumns>
          <DateRangePicker
            onStartDateChange={(date) => {
              changeFilter({
                scheduledStartAfter: date || undefined,
              });
            }}
            onEndDateChange={(date) => {
              const newDate = date ? endOfDay(date) : new Date();
              changeFilter({
                scheduledStartBefore: newDate.getTime(),
              });
            }}
            disabled={loading}
            startDate={filters.scheduledStartAfter || null}
            endDate={filters.scheduledStartBefore || null}
          />
        </ResponsiveColumns>
      </Header>

      <Stack
        direction={{
          xs: "column",
          lg: "row",
        }}
        spacing={2}
        margin={3}
      >
        <Stack
          flex={1}
          spacing={2}
          order={{
            xs: 1,
            lg: 0,
          }}
        >
          {widgetGroups.map((group) => (
            <Box
              key={group.title}
              id={group.id || group.title.toLowerCase().replace(/\s/gi, "-")}
            >
              <Typography variant="h2" mb={1}>
                {group.title}
              </Typography>
              <Box>
                {group.rows.map(
                  (tile) =>
                    tile && (
                      <WidgetRow
                        key={group.title + tile.layout}
                        layout={tile.layout}
                      >
                        {tile.widgets}
                      </WidgetRow>
                    )
                )}
              </Box>
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  );
}

import { IconButton, onlyMobile } from "@ameelio/ui";
import { ArrowBack } from "@mui/icons-material";
import { Box, Stack, useMediaQuery } from "@mui/material";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import { ScreenSubtitle, ScreenTitle } from "@src/lib/typography";
import { FULL_WIDTH } from "@src/styles/styles";
import * as React from "react";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  subtitle?: string;
  breadcrumb?: React.ReactElement<typeof Breadcrumbs>;
  className?: string;
  extra?: ReactNode[] | null;
  children?: ReactNode;
  showBack?: boolean;
}

export default function Header({
  title,
  className,
  subtitle,
  extra,
  children,
  showBack,
  breadcrumb,
}: Props): ReactElement {
  const { t } = useTranslation();
  const isMobile = onlyMobile(useMediaQuery);

  return (
    <Box
      py={2}
      px={3}
      sx={{
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        height: "auto",
      }}
      className={className}
      component="header"
    >
      <Stack spacing={1} style={FULL_WIDTH}>
        {breadcrumb || null}
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="flex-start"
          gap={2}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" alignItems="flex-start">
            {showBack && (
              <IconButton
                ariaLabel={t("Back")}
                onClick={() => window.history.back()}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Stack spacing={1}>
              <ScreenTitle>{title}</ScreenTitle>
              {subtitle ? <ScreenSubtitle>{subtitle}</ScreenSubtitle> : null}
            </Stack>
          </Stack>
          {extra?.length ? (
            <Stack direction="row" spacing={2}>
              {extra?.map((item, idx) => (
                <Box key={idx} display="flex" alignItems="flex-end">
                  {item}
                </Box>
              ))}
            </Stack>
          ) : null}
        </Stack>
        {children ? (
          <Box sx={{ borderTop: 1, borderColor: "divider", pt: 2 }}>
            {children}
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
}

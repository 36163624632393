import InmateLink from "@src/lib/InmateLink";
import StaffLink from "@src/lib/StaffLink";
import VisitorLink from "@src/lib/VisitorLink";
import { GetStaffTimelineQuery } from "./GetStaffTimeline.generated";

type StaffTimelineEvent =
  GetStaffTimelineQuery["staff"]["timelineEvents"]["edges"][0]["node"];

const TimelineEventAffectedParties = ({
  event,
}: {
  event: StaffTimelineEvent;
}) => {
  const object = event.object;

  switch (object.__typename) {
    case "Message":
    case "ConnectionRestriction":
      return (
        <div className="flex gap-4">
          <InmateLink inmate={object.connection.inmate} />
          <VisitorLink visitor={object.connection.visitor} />
        </div>
      );
    case "Meeting":
      return (
        <div className="flex gap-4">
          {object.inmates.length > 0 && (
            <InmateLink inmate={object.inmates[0]} />
          )}
          {object.visitors.length > 0 && (
            <VisitorLink visitor={object.visitors[0]} />
          )}
        </div>
      );
    case "MessageKeyword":
      return <div />;
    case "Connection":
      return (
        <div className="flex gap-4">
          <InmateLink inmate={object.inmate} />
          <VisitorLink visitor={object.visitor} />
        </div>
      );
    case "StaffPosition":
      return <StaffLink staff={object.staff} />;
    case "StaffEntitlement":
      return <StaffLink staff={object.staff} />;
    case "SystemUser":
      return <VisitorLink visitor={object.user} />;
    case "Kiosk":
      return <div />;
    default:
      return <div />;
  }
};

export default TimelineEventAffectedParties;

import { AspectRatio } from "@ameelio/ui";
import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import Avatar from "@src/lib/Avatar";
import { MENU_BACKGROUND_COLOR, MENU_SECONDARY_COLOR } from "@src/theme";

type Props = {
  names: string[];
  onClick?: () => void;
};

const FlexCentered: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function RecordingPlaceholder({ names, onClick }: Props) {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        height: "100%",
        backgroundColor: MENU_BACKGROUND_COLOR,
        cursor: "pointer",
      }}
    >
      <Stack direction="row" p={1} gap={1} sx={[FlexCentered, { height: 1 }]}>
        {names.map((name, idx) => (
          <Box key={idx} sx={{ flex: 1 }}>
            <AspectRatio ratio={16 / 9}>
              <Box
                sx={[
                  FlexCentered,
                  {
                    backgroundColor: MENU_SECONDARY_COLOR,
                    width: 1,
                    height: 1,
                  },
                ]}
              >
                <Avatar
                  fallback={name}
                  style={{
                    // max square
                    height: "100%",
                    width: "56.25%",
                  }}
                />
              </Box>
            </AspectRatio>
          </Box>
        ))}
      </Stack>
      <Box
        sx={[
          FlexCentered,
          { position: "absolute", top: 0, left: 0, right: 0, bottom: 0 },
        ]}
      >
        <PlayCircleFilledOutlined
          sx={{
            fontSize: 64,
            color: "white",
            borderRadius: 999,
            backgroundColor: "primary.main",
            "&:hover": {
              fontSize: 64 * 1.25,
            },
          }}
        />
      </Box>
    </Box>
  );
}

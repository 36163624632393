import { Box } from "@mui/system";
import React from "react";

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

export default function MessagesListHeader({ children }: Props) {
  return (
    <Box sx={{ backgroundColor: "white", position: "sticky", top: 0 }}>
      {children}
    </Box>
  );
}

import { Button } from "@ameelio/ui/src/lib/Button";
import { Box, Typography } from "@mui/material";
import ConnectionDetailDrawer from "@src/lib/Profiles/ConnectionDetailDrawer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TConnectionListItem } from "./ConnectionListSidebar/ConnectionList";

interface Props {
  connection: TConnectionListItem;
}

const MessagesListHeaderContent = ({ connection }: Props) => {
  const [detailDrawerIsOpen, setDetailDrawerIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: { lg: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        minHeight: "64px",
        height: "auto",
        borderBottomWidth: 1,
      }}
    >
      <Typography variant="h2">
        {connection.inmate.fullName} {"&"} {connection.visitor.fullName}{" "}
      </Typography>
      <Button variant="text" onClick={() => setDetailDrawerIsOpen(true)}>
        {t("See connection")}
      </Button>
      <ConnectionDetailDrawer
        connectionId={connection.id}
        isOpen={detailDrawerIsOpen}
        onClose={() => setDetailDrawerIsOpen(false)}
      />
    </Box>
  );
};

export default MessagesListHeaderContent;

import { Card, Link, Stack, Typography } from "@mui/material";
import {
  ACCESSIBILITY_SUPPORT_ADDRESS,
  FACILITY_SUPPORT_ADDRESS,
  STAFF_HELP_ARTICLES_URL,
  VISITOR_ACCESS_URL,
  VISITOR_HELP_ARTICLES_URL,
  VISITOR_SUPPORT_ADDRESS,
} from "@src/lib/constants";
import { DetailsStackItem } from "@src/lib/DetailsStack";
import Header from "@src/lib/Header";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { SectionTitle } from "@src/lib/typography";
import { useTranslation } from "react-i18next";

export default function StaffSupportPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const appEnv = import.meta.env.VITE_APP_ENV;
  const inmateAccessUrl =
    appEnv === "development"
      ? `http://localhost:7002/login/${facility.system.code.toLowerCase()}`
      : `https://${facility.system.code.toLowerCase()}-app.ameelio.${
          appEnv === "staging" ? "xyz" : "org"
        }`;
  return (
    <>
      <Header title={t("Help")} />
      <Card variant="outlined" sx={{ borderRadius: 3, m: 3 }}>
        <Stack padding={3} spacing={3}>
          <Stack spacing={1.5}>
            <SectionTitle>{t("Staff resources")}</SectionTitle>
            <DetailsStackItem label={t("Support")}>
              <Link href={`mailto:${FACILITY_SUPPORT_ADDRESS}`}>
                {FACILITY_SUPPORT_ADDRESS}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Feedback")}>
              <Link href={`mailto:${FACILITY_SUPPORT_ADDRESS}`}>
                {FACILITY_SUPPORT_ADDRESS}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Accessibility")}>
              <Link href={`mailto:${ACCESSIBILITY_SUPPORT_ADDRESS}`}>
                {ACCESSIBILITY_SUPPORT_ADDRESS}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Help articles")}>
              <Link
                href={STAFF_HELP_ARTICLES_URL}
                target="_blank"
                rel="noreferrer"
              >
                {STAFF_HELP_ARTICLES_URL}
              </Link>
            </DetailsStackItem>
          </Stack>
          <Typography variant="body1" color="text.primary">
            {t(
              "Facility support is available Monday through Friday from 9-5pm (09:00-17:00) US Central Time, except on US federal holidays."
            )}
          </Typography>
          <Stack spacing={1.5}>
            <SectionTitle>{t("Visitor resources")}</SectionTitle>
            <DetailsStackItem label={t("Support")}>
              <Link href={`mailto:${VISITOR_SUPPORT_ADDRESS}`}>
                {VISITOR_SUPPORT_ADDRESS}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Accessibility")}>
              <Link href={`mailto:${ACCESSIBILITY_SUPPORT_ADDRESS}`}>
                {ACCESSIBILITY_SUPPORT_ADDRESS}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Help articles")}>
              <Link
                href={VISITOR_HELP_ARTICLES_URL}
                target="_blank"
                rel="noreferrer"
              >
                {"https://ameeliocustomerservice.zendesk.com"}
              </Link>
            </DetailsStackItem>
            <DetailsStackItem label={t("Access")}>
              <Link href={VISITOR_ACCESS_URL} target="_blank" rel="noreferrer">
                {VISITOR_ACCESS_URL}
              </Link>
            </DetailsStackItem>
          </Stack>
          <Typography variant="body1" color="text.primary">
            {t(
              "Customer support is available Monday through Friday from 9-5pm (09:00-17:00) US Central Time, except on US federal holidays."
            )}
          </Typography>
          <SectionTitle>{t("Resident resources")}</SectionTitle>
          <DetailsStackItem label={t("Access")}>
            <Link href={inmateAccessUrl} target="_blank" rel="noreferrer">
              {inmateAccessUrl}
            </Link>
          </DetailsStackItem>
        </Stack>
      </Card>
    </>
  );
}

import { Stack, Typography } from "@mui/material";
import Spinner from "@src/lib/Spinner";
import { MENU_BACKGROUND_COLOR } from "@src/theme";
import { useTranslation } from "react-i18next";

export default function VideoConnecting() {
  const { t } = useTranslation();
  return (
    <Stack
      gap={2}
      sx={{
        width: 1,
        height: 1,
        flex: 1,
        display: "flex",
        overflow: "hidden",
        position: "relative",
        backgroundColor: MENU_BACKGROUND_COLOR,
        p: 4,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" color="white">
        {t("Connecting...")}
      </Typography>
      <Spinner />
    </Stack>
  );
}

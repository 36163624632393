import { Button } from "@ameelio/ui";
import { AlertTitle, Link, Stack, Typography } from "@mui/material";
import Alert from "@src/lib/Alert";
import { FACILITY_SUPPORT_ADDRESS } from "@src/lib/constants";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  title?: string;
  cta: string;
  onClick: () => void;
  children: React.ReactNode;
};

export function TranslateWithSupportLink({
  message,
  emailSubject,
}: {
  message: string;
  emailSubject: string;
}) {
  const { t } = useTranslation();
  return (
    <Trans
      t={t}
      defaults={message}
      components={{
        supportLink: (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
          <Link
            href={`mailto:${FACILITY_SUPPORT_ADDRESS}&subject=${emailSubject}`}
            target="_blank"
            rel="noreferrer"
          />
        ),
      }}
      values={{
        supportAddress: FACILITY_SUPPORT_ADDRESS,
      }}
    />
  );
}

export default function FeatureToggleBanner({
  title,
  children,
  cta,
  onClick,
}: Props) {
  return (
    <Alert severity="info">
      {title && <AlertTitle>{title}</AlertTitle>}
      <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
        <Typography variant="body1">{children}</Typography>
        <Button sx={{ flexShrink: 0 }} variant="contained" onClick={onClick}>
          {cta}
        </Button>
      </Stack>
    </Alert>
  );
}

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Link, Stack } from "@mui/material";
import {
  FacilityApprovedPhone,
  Inmate,
  Interval,
  MeetingStatus,
  MeetingType,
  PhoneCall,
  PrivacyLevel,
} from "@src/api/graphql";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import InmateLink from "@src/lib/InmateLink";
import MeetingContextMenu from "@src/lib/MeetingContextMenu";
import MeetingStatusTag from "@src/lib/MeetingStatusTag";
import PageLoader from "@src/lib/PageLoader";
import PhoneCallStatusTag from "@src/lib/PhoneCallStatusTag";
import MeetingsTableMeeting from "@src/lib/Profiles/MeetingsTableMeeting";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import VisitorList from "@src/lib/VisitorList";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PhoneCallRecord = Pick<PhoneCall, "id" | "privacyLevel" | "status"> & {
  interval: Pick<Interval, "startAt">;
  inmates: Pick<Inmate, "id" | "inmateIdentification" | "fullName">[];
  facilityApprovedPhone: Pick<FacilityApprovedPhone, "id" | "name" | "number">;
};

function isPhoneCallRecord(
  record: PhoneCallRecord | MeetingsTableMeeting
): record is PhoneCallRecord {
  return "facilityApprovedPhone" in record;
}

interface Props {
  meetings: (PhoneCallRecord | MeetingsTableMeeting)[];
  loading?: boolean;
}

export default function ScheduledMeetingsTable({ meetings, loading }: Props) {
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <TableContainer>
        <Table aria-label={t("Scheduled meetings table")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Start time")}</TableCell>
              <TableCell>{t("Residents")}</TableCell>
              <TableCell>{t("Visitors")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell>{t("Location/Device")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meetings.length === 0 && <TableNoData colSpan={7} />}
            {meetings.length > 0 &&
              currentPageData.map((meeting) => {
                return (
                  <TableRow key={meeting.id}>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "date")}
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(meeting.interval.startAt), "time")}
                    </TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {meeting.inmates.map((inmate) => (
                          <InmateLink key={inmate.id} inmate={inmate} />
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <>
                          {meeting.facilityApprovedPhone.name} (
                          {meeting.facilityApprovedPhone.number})
                        </>
                      ) : (
                        <VisitorList
                          visitors={meeting.visitors}
                          unregisteredGuests={meeting.unregisteredGuests}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? (
                        <PhoneCallStatusTag status={meeting.status} />
                      ) : meeting.status === MeetingStatus.Live &&
                        meeting.meetingType !== MeetingType.InPersonVisit &&
                        meeting.privacyLevel === PrivacyLevel.Monitored ? (
                        <Link
                          onClick={() =>
                            navigate(
                              meeting.meetingType === MeetingType.VoiceCall
                                ? `/live/voice-calls`
                                : `/live/video-calls`
                            )
                          }
                        >{`${getMeetingStatusLabelByType(
                          meeting.status
                        ).toUpperCase()} - ${t("Monitor now")}`}</Link>
                      ) : (
                        <MeetingStatusTag
                          status={meeting.status}
                          meetingType={meeting.meetingType}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) || !meeting.kiosk
                        ? "-"
                        : meeting.kiosk.name}
                    </TableCell>
                    <TableCell>
                      {isPhoneCallRecord(meeting) ? null : (
                        <SearchMeetingsTableActionButtons meeting={meeting} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={meetings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
}

const SearchMeetingsTableActionButtons: React.FC<{
  meeting: MeetingsTableMeeting;
}> = ({ meeting }) => {
  const navigate = useAppNavigate();
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate(`/meetings/${meeting.id}`)}
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("See details")}
      </Button>
      <MeetingContextMenu meeting={meeting} />
    </Stack>
  );
};

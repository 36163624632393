import { LockOutlined } from "@ant-design/icons";
import { QueryResult } from "@apollo/client";
import { Stack, Typography as MUITypography } from "@mui/material";
import { PrivacyLevel } from "@src/api/graphql";
import MessageDisplay from "@src/lib/MessageDisplay";
import ScreenSection from "@src/lib/ScreenSection";
import Spinner from "@src/lib/Spinner";
import { GetCallWithRecordingQuery } from "@src/pages/ArtifactsPage/GetCallWithRecording.generated";
import { GetMeetingDetailsQuery } from "@src/pages/MeetingDetailsPage/GetMeetingDetails.generated";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export type InCallChatProps = {
  meeting: GetMeetingDetailsQuery["meeting"];
  canReviewRecordings: boolean;
  allowedToAccessRecordings?: boolean;
  artifactsQuery: QueryResult<GetCallWithRecordingQuery, { id: string }>;
};

export default function InCallChatModule({
  meeting,
  canReviewRecordings,
  allowedToAccessRecordings,
  artifactsQuery,
}: InCallChatProps): ReactElement {
  const { t } = useTranslation();
  return (
    <ScreenSection
      titlePosition="inner"
      title={t("In-Call Chat")}
      sx={{ maxHeight: "75vh", overflow: "auto" }}
    >
      <Stack spacing={2}>
        {meeting.privacyLevel !== PrivacyLevel.Monitored ? (
          <>
            <LockOutlined />{" "}
            {t("Messages are not available on confidential meetings.")}
          </>
        ) : !canReviewRecordings ? (
          <>
            <LockOutlined />{" "}
            {t(
              "Only staff with permission to review recordings can see call messages."
            )}
          </>
        ) : !allowedToAccessRecordings ? (
          <>
            <LockOutlined />{" "}
            {t(
              "You do not have the required staff position to view this call's messages."
            )}
          </>
        ) : !artifactsQuery.data ? (
          <Spinner />
        ) : artifactsQuery.data.meeting.call?.messages.edges.length ? (
          artifactsQuery.data.meeting.call?.messages.edges.map(
            ({ node: message }) => (
              <MessageDisplay
                key={message.id}
                message={{
                  callId: meeting.id,
                  senderType: message.sender.__typename,
                  senderName:
                    message.sender.__typename === "Staff"
                      ? t("Facility Staff")
                      : message.sender.fullName,
                  contents: message.contents,
                  createdAt: message.createdAt,
                }}
              />
            )
          )
        ) : (
          <MUITypography variant="body2" color="text.secondary">
            {t("No chat messages were sent")}
          </MUITypography>
        )}
      </Stack>
    </ScreenSection>
  );
}

import { shortMonthDate } from "@src/lib/Time";
import { eachDayOfInterval, format } from "date-fns";

export function countsInInterval(
  interval: { start: Date; end: Date },
  data: { group: string; count: number }[]
): Record<string, number> {
  const byDay = data.reduce<Record<string, number>>(
    (memo, i) => ({ ...memo, [i.group]: i.count }),
    {}
  );
  const eachDay: [string, number][] = eachDayOfInterval(interval).map((d) => {
    return [shortMonthDate(d), byDay[format(d, "yyyy-MM-dd")] || 0];
  });
  return Object.fromEntries(eachDay);
}

export type RatingData = { group: string; count: number }[];
export type ElaboratedRatings = {
  average: string;
  ratings: number[];
};

export function elaborateRatings(data: RatingData): ElaboratedRatings {
  const ratings = [1, 2, 3, 4, 5].map(
    (n) => data.find((r) => r.group.toString() === n.toString())?.count || 0
  );
  const total = ratings.reduce((acc, r) => acc + r, 0);
  const average = total
    ? (ratings.reduce((acc, r, i) => acc + r * (i + 1), 0) / total).toFixed(1)
    : "";
  return {
    average,
    ratings: ratings.reverse(),
  };
}

import {
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
} from "@mui/material";

export default function Spinner({ title, ...props }: CircularProgressProps) {
  return (
    <Stack
      spacing={2}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size="24px" {...props} />
      {title && <Typography variant="body1">{title}</Typography>}
    </Stack>
  );
}

import { Box } from "@mui/material";
import Spinner from "@src/lib/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function PageLoader({
  title,
}: {
  title?: string;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner title={title || t("Loading...")} />
    </Box>
  );
}

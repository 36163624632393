import { Button, Tooltip } from "@ameelio/ui";
import { LockOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Link as MUILink,
  Stack,
  Typography as MUITypography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  Entitlement,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
  TimelineEventVerb,
} from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import { getFullNameWithId } from "@src/lib/Common";
import DetailsStack, { DetailsStackItem } from "@src/lib/DetailsStack";
import EndMeetingButton from "@src/lib/EndMeetingButton";
import getCallEndTime from "@src/lib/getCallEndTime";
import GuestList from "@src/lib/GuestList";
import Header from "@src/lib/Header";
import mayHaveRecording from "@src/lib/mayHaveRecording";
import mayHaveRecords from "@src/lib/mayHaveRecords";
import MeetingStatusTag from "@src/lib/MeetingStatusTag";
import NoShowMeetingButton from "@src/lib/NoShowMeetingButton";
import PageLoader from "@src/lib/PageLoader";
import RequestRecordingButton from "@src/lib/RequestRecordingButton";
import ScreenSection from "@src/lib/ScreenSection";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import statusDetailsLabel from "@src/lib/statusDetailsLabel";
import { formatDate, hoursMinutesSecondsTextLabel } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import ViewRecordsButton from "@src/lib/ViewRecordsButton";
import VisitTypeIcon from "@src/lib/VisitTypeIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AcceptMeetingButton from "../MeetingRequestsPage/AcceptMeetingButton";
import DeclineMeetingButton from "../MeetingRequestsPage/DeclineMeetingButton";
import { GetCallArtifactsDocument } from "./GetCallArtifacts.generated";
import { GetMeetingDetailsDocument } from "./GetMeetingDetails.generated";
import "./MeetingDetails.css";

const formatTimelineVerb = (
  verb: TimelineEventVerb,
  metadataJSON?: string | null
): string => {
  const metadata = (() => {
    try {
      return metadataJSON && JSON.parse(metadataJSON);
    } catch {
      return;
    }
  })();
  const basicStatusStr =
    verb.toString().charAt(0).toUpperCase() + verb.slice(1).toLowerCase();
  switch (verb) {
    case TimelineEventVerb.Approve:
      return i18n.t("Approved");
    case TimelineEventVerb.Cancel:
      return i18n.t("Canceled");
    case TimelineEventVerb.Create:
      return i18n.t("Scheduled");
    case TimelineEventVerb.Disable:
      return i18n.t("Disabled");
    case TimelineEventVerb.Enable:
      return i18n.t("Enabled");
    case TimelineEventVerb.Read:
      return metadata?.detail === "accessed_recording"
        ? i18n.t("Recording accessed")
        : i18n.t("Read");
    case TimelineEventVerb.Reject:
      return i18n.t("Declined");
    case TimelineEventVerb.Update:
      return i18n.t("Updated");
    default:
      return `${basicStatusStr}`;
  }
};

function MeetingDetailsPage(): ReactElement {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { id: meetingId } = useParams<{ id: string }>();

  if (!meetingId) throw new Error("missing meetingId param");

  const { facility } = useGuaranteedFacilityContext();
  const { staffPositions } = useCurrentStaff();

  const { loading, error, data } = useQuery(GetMeetingDetailsDocument, {
    variables: { id: meetingId },
  });
  if (error) throw error;

  // load messages when allowed
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const canReviewScreenshots = useEntitlement(Entitlement.ReviewScreenshots);
  const allowedToAccessRecordings =
    canReviewRecordings &&
    data?.meeting &&
    staffPositions.some((sp) => sp.facility.id === data.meeting.facility.id);
  const allowedToAccessScreenshots =
    canReviewScreenshots &&
    data?.meeting &&
    staffPositions.some((sp) => sp.facility.id === data.meeting.facility.id);
  const allowedToAccessRecords =
    allowedToAccessRecordings || allowedToAccessScreenshots;
  const artifactsQuery = useQuery(GetCallArtifactsDocument, {
    variables: { meetingId },
    skip: !allowedToAccessRecordings,
  });
  const canManageMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  if (loading || !data?.meeting) return <PageLoader />;

  const { meeting } = data;

  const meetingNeverLive =
    meeting.status === MeetingStatus.NoShow || !meeting.call?.duration;
  const meetingDuration = meetingNeverLive
    ? "–"
    : hoursMinutesSecondsTextLabel(meeting.call?.duration);

  const callTimelineEvents = meeting.call?.timelineEvents || [];
  const meetingTimelineEvents = meeting.timelineEvents || [];
  const timelineEvents = [...callTimelineEvents, ...meetingTimelineEvents].sort(
    (a, b) => a.createdAt - b.createdAt
  );

  const { primaryVisitor } = meeting;
  const registeredGuests = meeting.visitors.filter(
    (v) => v.id !== primaryVisitor?.id
  );

  const meetingName = meeting.id.split("-")[0];

  return (
    <>
      <Header
        className="hide-for-print"
        title={t("Meeting") + " " + meetingName}
        extra={[
          mayHaveRecords(meeting) && allowedToAccessRecords && (
            <ViewRecordsButton
              key="view-records"
              meeting={meeting}
              size="small"
            />
          ),
          mayHaveRecording(meeting) &&
            allowedToAccessRecordings &&
            artifactsQuery.data?.meeting && (
              <RequestRecordingButton
                key="request-recording"
                meeting={artifactsQuery.data.meeting}
                size="small"
              />
            ),
          meeting.status === MeetingStatus.Live &&
            meeting.meetingType !== MeetingType.InPersonVisit &&
            canManageMeetings && (
              <EndMeetingButton
                key="end-meeting"
                meeting={meeting}
                size="small"
              />
            ),
          meeting.status === MeetingStatus.Ended &&
            canManageMeetings &&
            meeting.meetingType === MeetingType.InPersonVisit && (
              <NoShowMeetingButton
                key="no-show-meeting"
                meeting={meeting}
                size="small"
              />
            ),
          [MeetingStatus.PendingApproval, MeetingStatus.Scheduled].includes(
            meeting.status
          ) &&
            canManageMeetings && (
              <DeclineMeetingButton
                key="decline-meeting"
                meeting={meeting}
                size="small"
              />
            ),
          meeting.status === MeetingStatus.PendingApproval &&
            canManageMeetings && (
              <AcceptMeetingButton
                key="accept-meeting"
                meeting={meeting}
                size="small"
              />
            ),
          [MeetingStatus.Scheduled, MeetingStatus.PendingApproval].includes(
            meeting.status
          ) &&
            canManageMeetings && (
              <Button
                size="small"
                key="modify-event"
                variant="contained"
                onClick={() => {
                  navigate(
                    `/inmates/${meeting.inmates[0].id}/event/${meeting.id}/edit`
                  );
                }}
              >
                {t("Modify event")}
              </Button>
            ),
        ].filter(Boolean)}
        breadcrumb={
          <Breadcrumbs
            paths={[
              {
                path: "/meetings/history",
                label: "Meetings",
              },
              {
                path: `/meetings/${meetingId}`,
                label: meetingName,
              },
            ]}
          />
        }
        showBack
      />
      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={2}
        p={3}
        className="printable-region"
      >
        <Box flex={2}>
          <ScreenSection title={t("Event Details")} sx={{ mb: 2 }}>
            <DetailsStack>
              {mayHaveRecording(meeting) && canReviewRecordings && (
                <DetailsStackItem label={t("Recording Expiration")}>
                  <MUITypography
                    variant="body1"
                    color="primary"
                    fontWeight="bold"
                  >
                    {meeting.call &&
                      meeting.call.artifactsExpireAt &&
                      `${formatDate(
                        new Date(meeting.call.artifactsExpireAt),
                        "yearshortmonthdate"
                      )} at ${formatDate(
                        new Date(meeting.call.artifactsExpireAt),
                        "time"
                      )}`}
                  </MUITypography>
                  <Tooltip
                    className="hide-for-print"
                    title={t(
                      "Recordings permanently expire {{count}} days after the meeting start time.",
                      { count: facility.system.recordingRetention }
                    )}
                  >
                    <InfoOutlined sx={{ color: grey["900"] }} />
                  </Tooltip>
                </DetailsStackItem>
              )}
              <DetailsStackItem label={t("Type")}>
                <VisitTypeIcon type={meeting.meetingType} />
              </DetailsStackItem>
              <DetailsStackItem
                label={t("Date")}
                value={formatDate(new Date(meeting.interval.startAt), "date")}
              />
              <DetailsStackItem
                label={t("Start time")}
                value={formatDate(
                  new Date(meeting.interval.startAt),
                  meeting.meetingType === MeetingType.VoiceCall
                    ? "timeseconds"
                    : "time"
                )}
              />
              <DetailsStackItem
                label={t("End Time")}
                value={
                  getCallEndTime(
                    meeting.status,
                    meeting.meetingType,
                    meeting.interval,
                    meeting.call?.duration,
                    meeting.meetingType === MeetingType.VoiceCall
                  ) || "-"
                }
              />
              <DetailsStackItem label={t("Duration")} value={meetingDuration} />
              <DetailsStackItem
                label={t("Residents")}
                value={meeting.inmates
                  .map((i) => getFullNameWithId(i))
                  .join(", ")}
              />
              {meeting.primaryVisitor && (
                <DetailsStackItem
                  label={t("Visitor")}
                  value={`${meeting.primaryVisitor.fullName} (${meeting.primaryVisitor.email})`}
                />
              )}
              {(registeredGuests.length > 0 ||
                meeting.unregisteredGuests.length > 0) && (
                <DetailsStackItem label={t("Guests")}>
                  <GuestList
                    registeredGuests={registeredGuests}
                    unregisteredGuests={meeting.unregisteredGuests}
                  />
                </DetailsStackItem>
              )}
              <DetailsStackItem
                label={t("Scheduler")}
                value={
                  meeting.visitors
                    .map((v) => v.id)
                    .includes(meeting.schedulerId)
                    ? t("Visitor")
                    : t("Staff")
                }
              />
              <DetailsStackItem label={t("Status")}>
                <Box>
                  <MeetingStatusTag
                    status={meeting.status}
                    meetingType={meeting.meetingType}
                  />
                </Box>
              </DetailsStackItem>
              <DetailsStackItem
                label={t("Status details")}
                value={
                  meeting.statusDetails
                    ? statusDetailsLabel(meeting.statusDetails)
                    : "–"
                }
              />
              <DetailsStackItem label={t("Confidential")}>
                {meeting.privacyLevel !== PrivacyLevel.Monitored ? (
                  <MUITypography variant="body1">
                    <LockOutlined /> {t("Yes")}
                  </MUITypography>
                ) : (
                  <MUITypography variant="body1">{t("No")}</MUITypography>
                )}
              </DetailsStackItem>
              <DetailsStackItem
                label={t("Kiosk")}
                value={meeting.kiosk?.name || "–"}
              />
              <DetailsStackItem label={t("Meeting ID")} value={meeting.id} />
            </DetailsStack>
          </ScreenSection>

          {timelineEvents && timelineEvents.length ? (
            <ScreenSection
              title={t("History")}
              className={
                // At the following system in Prod there is a request
                // to not include the History section in the printed version
                facility.system.id === "025cf4ac-8752-4c93-9779-d9fd1fd17754"
                  ? "hide-for-print"
                  : "print-on-new-page"
              }
            >
              <DetailsStack>
                {timelineEvents.map((timelineEvent) => {
                  return (
                    <DetailsStackItem
                      key={timelineEvent.id}
                      label={`${formatDate(
                        new Date(timelineEvent.createdAt),
                        "datetime"
                      )}`}
                      children={
                        <>
                          <MUITypography variant="body1" color="text.primary">
                            {`${formatTimelineVerb(
                              timelineEvent.verb,
                              timelineEvent.metadata
                            )} ${t("by")} `}
                            <MUILink
                              onClick={() =>
                                navigate(
                                  `/system/staff/${timelineEvent.staff.id}`
                                )
                              }
                            >
                              {timelineEvent.staff.fullName}
                            </MUILink>
                          </MUITypography>
                        </>
                      }
                    />
                  );
                })}
              </DetailsStack>
            </ScreenSection>
          ) : null}
        </Box>
      </Stack>
    </>
  );
}

export default MeetingDetailsPage;

import { InlineBadge, Media } from "@ameelio/ui";
import { PersonOutline } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { blue, grey } from "@mui/material/colors";
import { Message } from "@src/lib/Message";
import { formatDate } from "@src/lib/Time";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { TConnectionListItem } from "./ConnectionList";

interface Props {
  onSelect: () => void;
  isSelected?: boolean;
  connection: TConnectionListItem;
  totalFlaggedMessages?: number;
  oldestMessage: Message;
}

export default function ConnectionListItem({
  connection,
  onSelect,
  isSelected,
  totalFlaggedMessages,
  oldestMessage,
}: Props) {
  const { t } = useTranslation();
  const getTimestamp = (): string => {
    // messages are sorted by created at
    const hoursSince = differenceInHours(new Date(), oldestMessage.createdAt);

    if (hoursSince < 24) {
      if (hoursSince <= 1) {
        return t("{{count}} minutes ago", {
          count: differenceInMinutes(new Date(), oldestMessage.createdAt),
        });
      }
      return t("{{count}} hours ago", {
        count: hoursSince,
      });
    }
    if (hoursSince < 48) {
      return t("Yesterday");
    } else {
      return formatDate(oldestMessage.createdAt, "date");
    }
  };

  return (
    <Box
      onClick={onSelect}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        px: 2,
        py: 1,
        backgroundColor: isSelected ? blue[50] : "inherit",
        "&:hover": {
          backgroundColor: grey[50],
        },
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="body1">
          {connection.inmate.fullName}
          {totalFlaggedMessages && (
            <InlineBadge badgeContent={totalFlaggedMessages} sx={{ ml: 1 }} />
          )}
        </Typography>
        <Media
          gap={1}
          image={<PersonOutline sx={{ color: "text.secondary" }} />}
        >
          <Typography variant="body2" color="text.secondary">
            {connection.visitor.fullName}
          </Typography>
        </Media>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {getTimestamp()}
      </Typography>
    </Box>
  );
}

import { Link } from "@mui/material";
import { useAppNavigate } from "@src/lib/useAppNavigate";

interface Props {
  visitor: {
    id: string;
    fullName: string;
  };
}

export default function VisitorLink({ visitor }: Props) {
  const navigate = useAppNavigate();
  return (
    <Link onClick={() => navigate(`/visitors/${visitor.id}`)}>
      {visitor.fullName}
    </Link>
  );
}

import Box from "@mui/material/Box";
import { MESSAGE_PANEL_SIDEBAR_WIDTH } from "@src/pages/MessageReviewPage/MessageReviewPanel/MessagePanelStructure";

interface Props {
  children: React.ReactNode;
}

export default function ConnectionListSidebar({ children }: Props) {
  return (
    <Box
      sx={{
        background: "white",
        overflowY: "auto",

        // Width
        width: {
          xs: "100%",
          md: "300px",
        },
        minWidth: MESSAGE_PANEL_SIDEBAR_WIDTH,

        // Height
        height: "100%",
        minHeight: "150px",
        maxHeight: {
          xs: "200px",
          md: "100%",
        },

        // Border
        borderWidth: {
          xs: "1px",
          md: "1px 0 1px 1px",
        },
        borderColor: "divider",
      }}
    >
      {children}
    </Box>
  );
}

import { Media } from "@ameelio/ui";
import {
  CallOutlined,
  PresentToAll,
  TableRestaurant,
  Videocam,
} from "@mui/icons-material";
import { MeetingType } from "@src/api/graphql";
import { useTranslation } from "react-i18next";

interface Props {
  type: MeetingType;
}

const IconMap: Record<MeetingType, JSX.Element> = {
  [MeetingType.VideoCall]: <Videocam />,
  [MeetingType.InPersonVisit]: <TableRestaurant />,
  [MeetingType.Webinar]: <PresentToAll />,
  [MeetingType.VoiceCall]: <CallOutlined />,
};

export default function VisitTypeIcon({ type }: Props) {
  const { t } = useTranslation();
  switch (type) {
    case MeetingType.VideoCall:
      return (
        <Media gap={1} image={IconMap[type]}>
          {t("Video Call")}
        </Media>
      );
    case MeetingType.InPersonVisit:
      return (
        <Media gap={1} image={IconMap[type]}>
          {t("In-Person")}
        </Media>
      );
    case MeetingType.VoiceCall:
      return (
        <Media gap={1} image={IconMap[type]}>
          {t("Voice Call")}
        </Media>
      );
    case MeetingType.Webinar:
      return (
        <Media gap={1} image={IconMap[type]}>
          {t("Webinar")}
        </Media>
      );
    default:
      return <div />;
  }
}

import { SnackbarProvider } from "@ameelio/ui";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { inferBrowserLanguage } from "@src/lib/Language";
import ResetScroll from "@src/lib/ResetScroll";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRoutes } from "react-router-dom";
import "./App.css";
import AuthenticatedClient from "./AuthenticatedClient";
import AuthProvider from "./AuthProvider";
import FacilityProvider from "./FacilityProvider";
import ErrorBoundary from "./lib/ErrorBoundary";
import { routes } from "./pages/routes";
import { theme } from "./theme";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    release: import.meta.env.VITE_SENTRY_RELEASE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [new Sentry.BrowserTracing()],
  });
}

function App() {
  const Screen = useRoutes(routes);
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(inferBrowserLanguage());
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider>
            <AuthProvider>
              <FacilityProvider>
                <AuthenticatedClient>
                  <ResetScroll key={location.pathname} />
                  {Screen}
                </AuthenticatedClient>
              </FacilityProvider>
            </AuthProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

import { Link } from "@mui/material";
import { getFullNameWithId } from "@src/lib/Common";
import { useAppNavigate } from "@src/lib/useAppNavigate";

interface Props {
  inmate: {
    id: string;
    inmateIdentification: string;
    fullName: string;
  };
}

export default function InmateLink({ inmate }: Props) {
  const navigate = useAppNavigate();
  return (
    <Link onClick={() => navigate(`/inmates/${inmate.id}`)}>
      {getFullNameWithId(inmate)}
    </Link>
  );
}
